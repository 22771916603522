
import { computed, defineComponent, PropType, reactive } from 'vue'
import { Voice } from '@/modules/ciso/model/voices.model'
import { isFormErrored, isFormLoading, isFormSuccess } from '@/utils/form-state'
import { VoiceLabelsDefinition } from '@/modules/ciso/model/voice-label.model'
import { getVoiceLabelsDefinition } from '@/modules/ciso/api/voices.api'
import { errored, isLoaded, loaded, LOADING_STATE, LoadingState } from '@/utils/loading-state'

export default defineComponent({
  name: 'VoiceLabelsEditor',
  methods: { isLoaded },
  components: {},
  props: {
    voice: {
      type: Object as PropType<Voice>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const data = reactive({
      labelsDefinition: LOADING_STATE as LoadingState<VoiceLabelsDefinition>,
    })

    const localVoice = computed({
      get: () => props.voice,
      set: (value: Voice) => emit('update:voice', value),
    })

    const labelsCategories = computed(() =>
      isLoaded(data.labelsDefinition) ? Object.keys(data.labelsDefinition.data) : []
    )

    const setCheckboxState = (category: string, label: string, newState: boolean) => {
      localVoice.value.labels = localVoice.value.labels.filter((v) => !(v.label === label && v.category === category))

      if (newState) {
        localVoice.value.labels.push({
          label,
          category,
        })
      }
    }

    const selectSingularLabel = (category: string, label: string) => {
      localVoice.value.labels = localVoice.value.labels.filter((v) => v.category !== category)
      localVoice.value.labels.push({
        label,
        category,
      })
    }

    const hasManyLabels = (category: string) =>
      isLoaded(data.labelsDefinition) ? data.labelsDefinition.data[category].many : false

    const formatCategoryHeader = (v: string) => v.replace(/([a-z])([A-Z])/g, '$1 $2').toLocaleLowerCase()

    const fetchVoiceLabelsDefinition = async () => {
      try {
        data.labelsDefinition = LOADING_STATE
        const definition = await getVoiceLabelsDefinition()
        data.labelsDefinition = loaded(definition)
      } catch (e) {
        data.labelsDefinition = errored(e)
      }
    }

    fetchVoiceLabelsDefinition()

    return {
      data,
      localVoice,
      isFormLoading,
      isFormErrored,
      isFormSuccess,
      labelsCategories,
      formatCategoryHeader,
      hasManyLabels,
      setCheckboxState,
      selectSingularLabel,
    }
  },
})
