import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoBack = _resolveComponent("GoBack")!
  const _component_DataLoader = _resolveComponent("DataLoader")!
  const _component_DataLoadingError = _resolveComponent("DataLoadingError")!
  const _component_VoicePreviewsGeneratorUI = _resolveComponent("VoicePreviewsGeneratorUI")!
  const _component_va_divider = _resolveComponent("va-divider")!
  const _component_VoicePreviewsGeneratorStatus = _resolveComponent("VoicePreviewsGeneratorStatus")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, null, {
    header: _withCtx(() => [
      _createVNode(_component_GoBack, {
        to: { name: _ctx.CISORoute.Voices }
      }, null, 8, ["to"]),
      _createTextVNode(" Voice previews manager ")
    ]),
    content: _withCtx(() => [
      _createVNode(_component_DataLoader, {
        "is-loading": _ctx.isLoading(_ctx.data.previewsGenerator)
      }, null, 8, ["is-loading"]),
      _createVNode(_component_DataLoadingError, {
        subject: _ctx.data.previewsGenerator
      }, null, 8, ["subject"]),
      (_ctx.isLoaded(_ctx.data.previewsGenerator))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_VoicePreviewsGeneratorUI, {
              "is-generator-running": _ctx.data.previewsGenerator.data.isRunning
            }, null, 8, ["is-generator-running"]),
            _createVNode(_component_va_divider, { class: "mt-5 mb-5" }),
            _createVNode(_component_VoicePreviewsGeneratorStatus, {
              status: _ctx.data.previewsGenerator.data
            }, null, 8, ["status"])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}