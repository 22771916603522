import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c203589"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "labels"
}
const _hoisted_2 = { class: "labels__category-header" }
const _hoisted_3 = { class: "labels__checkboxes" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_checkbox = _resolveComponent("va-checkbox")!
  const _component_va_select = _resolveComponent("va-select")!

  return (_ctx.isLoaded(_ctx.data.labelsDefinition))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.labelsCategories, (labelCat) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "labels__category",
            key: labelCat
          }, [
            (_ctx.hasManyLabels(labelCat))
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.formatCategoryHeader(labelCat)), 1),
                  _createElementVNode("div", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.labelsDefinition.data[labelCat].values, (label) => {
                      return (_openBlock(), _createBlock(_component_va_checkbox, {
                        key: label,
                        label: label,
                        "model-value": !!_ctx.localVoice.labels.find((v) => v.label === label && v.category === labelCat),
                        "onUpdate:modelValue": (newState) => _ctx.setCheckboxState(labelCat, label, newState),
                        disabled: _ctx.disabled
                      }, null, 8, ["label", "model-value", "onUpdate:modelValue", "disabled"]))
                    }), 128))
                  ])
                ], 64))
              : (_openBlock(), _createBlock(_component_va_select, {
                  key: 1,
                  bordered: "",
                  label: _ctx.formatCategoryHeader(labelCat),
                  options: _ctx.data.labelsDefinition.data[labelCat].values,
                  "model-value": _ctx.localVoice.labels.find((v) => v.category === labelCat)?.label,
                  "onUpdate:modelValue": (label) => _ctx.selectSingularLabel(labelCat, label),
                  disabled: _ctx.disabled
                }, null, 8, ["label", "options", "model-value", "onUpdate:modelValue", "disabled"]))
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}