import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_button = _resolveComponent("va-button")!
  const _component_va_checkbox = _resolveComponent("va-checkbox")!
  const _component_DataLoader = _resolveComponent("DataLoader")!
  const _component_DataLoadingError = _resolveComponent("DataLoadingError")!
  const _component_va_alert = _resolveComponent("va-alert")!
  const _component_VoiceDetailsTable = _resolveComponent("VoiceDetailsTable")!
  const _component_va_modal = _resolveComponent("va-modal")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, null, {
    header: _withCtx(() => [
      _createTextVNode(" Voices "),
      _createVNode(_component_va_button, {
        class: "ml-4",
        onClick: _ctx.goToVoiceCreator,
        color: "success",
        icon: "add"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Add new voice")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createElementVNode("td", {
        onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
        class: "ml-5"
      }, [
        _createVNode(_component_va_checkbox, {
          modelValue: _ctx.data.destructiveActions,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.destructiveActions) = $event)),
          label: "Enable:"
        }, null, 8, ["modelValue"])
      ]),
      _createVNode(_component_va_button, {
        class: "ml-3",
        onClick: _cache[2] || (_cache[2] = () => (_ctx.data.showMigrateModal = true)),
        color: "warning",
        icon: "drive_file_move",
        disabled: !_ctx.data.destructiveActions || _ctx.copyToDevDisabled
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Copy selected to " + _toDisplayString(_ctx.remoteServerName), 1)
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(_component_va_button, {
        class: "ml-3",
        onClick: _cache[3] || (_cache[3] = () => (_ctx.data.showDeleteModal = true)),
        color: "danger",
        icon: "delete",
        disabled: !_ctx.data.destructiveActions
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Delete selected ")
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    content: _withCtx(() => [
      _createVNode(_component_DataLoader, {
        "is-loading": _ctx.isLoading(_ctx.data.voices)
      }, null, 8, ["is-loading"]),
      _createVNode(_component_DataLoadingError, {
        subject: _ctx.data.voices
      }, null, 8, ["subject"]),
      (_ctx.batchEditErr)
        ? (_openBlock(), _createBlock(_component_va_alert, {
            key: 0,
            color: "danger"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.batchEditErr), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.isLoaded(_ctx.data.voices))
        ? (_openBlock(), _createBlock(_component_VoiceDetailsTable, {
            key: 1,
            voices: _ctx.data.voices.data,
            onSelectedVoice: _cache[4] || (_cache[4] = (val) => _ctx.selectVoice(true, val)),
            onUnselectedVoice: _cache[5] || (_cache[5] = (val) => _ctx.selectVoice(false, val))
          }, null, 8, ["voices"]))
        : _createCommentVNode("", true),
      _createVNode(_component_va_modal, {
        modelValue: _ctx.data.showMigrateModal,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.data.showMigrateModal) = $event)),
        title: "Confirm",
        message: 
          `Are you sure you want to COPY selected voices to ${_ctx.remoteServerName?.toUpperCase()}? Voices(` +
          _ctx.selectedVoices.size +
          '): ' +
          _ctx.selectedVoicesNames(_ctx.selectedVoices)
        ,
        onOk: _ctx.migrateSelectedVoices
      }, null, 8, ["modelValue", "message", "onOk"]),
      _createVNode(_component_va_modal, {
        modelValue: _ctx.data.showDeleteModal,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.data.showDeleteModal) = $event)),
        title: "Confirm",
        message: 
          'Are you sure you want to DELETE selected voices? Voices(' +
          _ctx.selectedVoices.size +
          '): ' +
          _ctx.selectedVoicesNames(_ctx.selectedVoices)
        ,
        onOk: _ctx.deleteSelectedVoices
      }, null, 8, ["modelValue", "message", "onOk"])
    ]),
    _: 1
  }))
}