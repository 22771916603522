import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ebe4713e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 1,
  class: "mb-5"
}
const _hoisted_3 = {
  key: 2,
  class: "mb-2"
}
const _hoisted_4 = { class: "voice-previews mb-3" }
const _hoisted_5 = {
  key: 0,
  class: "info mb-3"
}
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormStateAlert = _resolveComponent("FormStateAlert")!
  const _component_PanelSubheading = _resolveComponent("PanelSubheading")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_va_file_upload = _resolveComponent("va-file-upload")!
  const _component_DataLoader = _resolveComponent("DataLoader")!
  const _component_DataLoadingError = _resolveComponent("DataLoadingError")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_VoiceLabelsEditor = _resolveComponent("VoiceLabelsEditor")!
  const _component_va_form = _resolveComponent("va-form")!
  const _component_va_modal = _resolveComponent("va-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_FormStateAlert, {
      "form-state": _ctx.data.formState
    }, null, 8, ["form-state"]),
    _createElementVNode("div", null, [
      _createVNode(_component_va_form, { class: "mb-5" }, {
        default: _withCtx(() => [
          _createVNode(_component_PanelSubheading, { class: "mb-3" }, {
            default: _withCtx(() => [
              _createTextVNode("General settings")
            ]),
            _: 1
          }),
          (!_ctx.isNewVoice)
            ? (_openBlock(), _createBlock(_component_va_input, {
                key: 0,
                bordered: "",
                class: "mb-3",
                label: "ID",
                modelValue: _ctx.localVoice.id,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localVoice.id) = $event)),
                disabled: ""
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "name",
            modelValue: _ctx.localVoice.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localVoice.name) = $event)),
            disabled: _ctx.isAnyLoading
          }, null, 8, ["modelValue", "disabled"]),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "key_tech",
            modelValue: _ctx.localVoice.key_tech,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localVoice.key_tech) = $event)),
            disabled: _ctx.isAnyLoading
          }, null, 8, ["modelValue", "disabled"]),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "key_model",
            modelValue: _ctx.localVoice.key_model,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localVoice.key_model) = $event)),
            disabled: _ctx.isAnyLoading
          }, null, 8, ["modelValue", "disabled"]),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "key_id",
            modelValue: _ctx.localVoice.key_id,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localVoice.key_id) = $event)),
            disabled: _ctx.isAnyLoading
          }, null, 8, ["modelValue", "disabled"]),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-5",
            label: "rating",
            type: "number",
            min: "0",
            max: "10",
            step: "0.5",
            modelValue: _ctx.localVoice.rating,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localVoice.rating) = $event)),
            disabled: _ctx.isAnyLoading
          }, null, 8, ["modelValue", "disabled"]),
          _createVNode(_component_PanelSubheading, { class: "mb-3" }, {
            default: _withCtx(() => [
              _createTextVNode("Speech data")
            ]),
            _: 1
          }),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "speech_key",
            modelValue: _ctx.localVoice.speech_key,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localVoice.speech_key) = $event)),
            disabled: _ctx.isAnyLoading
          }, null, 8, ["modelValue", "disabled"]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_va_input, {
              bordered: "",
              class: "mb-3",
              label: "speech_emb (base64)",
              modelValue: _ctx.localVoice.speech_emb,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localVoice.speech_emb) = $event)),
              disabled: _ctx.isAnyLoading
            }, null, 8, ["modelValue", "disabled"]),
            _createVNode(_component_va_file_upload, {
              class: "emb-inline flex mb-3",
              "onUpdate:modelValue": _ctx.onEmbFile,
              type: "single",
              dropZoneText: "drag/drop binary emb file",
              dropzone: "",
              disabled: _ctx.isAnyLoading
            }, null, 8, ["onUpdate:modelValue", "disabled"])
          ]),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-5",
            label: "speech_spk_emb",
            modelValue: _ctx.localVoice.speech_spk_emb,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localVoice.speech_spk_emb) = $event)),
            disabled: _ctx.isAnyLoading
          }, null, 8, ["modelValue", "disabled"]),
          _createVNode(_component_PanelSubheading, { class: "mb-3" }, {
            default: _withCtx(() => [
              _createTextVNode("Voice previews")
            ]),
            _: 1
          }),
          (_ctx.isNewVoice)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, "You can add voice preview after the voice is saved."))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_DataLoader, {
                  "is-loading": _ctx.isAnyLoading,
                  class: "mb-2"
                }, null, 8, ["is-loading"]),
                _createVNode(_component_DataLoadingError, {
                  subject: _ctx.data.reloadPreviews,
                  class: "mb-2"
                }, null, 8, ["subject"]),
                _createElementVNode("div", _hoisted_4, [
                  (_ctx.localVoice.preview_urls.length <= 0)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_5, "This voice has no previews."))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localVoice.preview_urls, (preview_url) => {
                    return (_openBlock(), _createElementBlock("audio", {
                      controls: "",
                      key: preview_url
                    }, [
                      _createElementVNode("source", {
                        src: preview_url,
                        type: "audio/wav"
                      }, null, 8, _hoisted_6)
                    ]))
                  }), 128))
                ]),
                _createVNode(_component_va_button, {
                  class: "mb-5",
                  onClick: _ctx.reloadPreviews,
                  disabled: _ctx.isAnyLoading
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Regenerate previews")
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled"])
              ])),
          _createVNode(_component_PanelSubheading, { class: "mb-3" }, {
            default: _withCtx(() => [
              _createTextVNode("Labels")
            ]),
            _: 1
          }),
          _createVNode(_component_VoiceLabelsEditor, {
            voice: _ctx.localVoice,
            "onUpdate:voice": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localVoice) = $event)),
            disabled: _ctx.isAnyLoading
          }, null, 8, ["voice", "disabled"]),
          (!_ctx.isNewVoice)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                _createVNode(_component_va_button, {
                  class: "mr-2",
                  onClick: _cache[10] || (_cache[10] = () => _ctx.actionHandler(_ctx.saveVoiceHandler)),
                  disabled: _ctx.isAnyLoading
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Save")
                  ]),
                  _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_va_button, {
                  class: "mr-2",
                  onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.data.showDeleteModal = true)),
                  color: "danger",
                  disabled: _ctx.isAnyLoading
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Delete ")
                  ]),
                  _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_va_button, {
                  class: "mr-2",
                  onClick: _ctx.cloneVoice,
                  color: "success",
                  disabled: _ctx.isAnyLoading
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Clone this voice ")
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled"])
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.isNewVoice)
            ? (_openBlock(), _createBlock(_component_va_button, {
                key: 4,
                class: "mr-2",
                onClick: _cache[12] || (_cache[12] = () => _ctx.actionHandler(_ctx.createVoiceHandler)),
                color: "success",
                disabled: _ctx.isAnyLoading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Create ")
                ]),
                _: 1
              }, 8, ["disabled"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_va_modal, {
        modelValue: _ctx.data.showDeleteModal,
        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.data.showDeleteModal) = $event)),
        title: "Confirm",
        message: "Are you sure you want to delete this voice?",
        onOk: _cache[14] || (_cache[14] = () => _ctx.actionHandler(_ctx.deleteVoiceHandler))
      }, null, 8, ["modelValue"])
    ])
  ], 64))
}