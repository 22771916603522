import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-273f9504"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_button = _resolveComponent("va-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_va_button, {
      onClick: _cache[0] || (_cache[0] = () => _ctx.reload('all')),
      disabled: _ctx.isGeneratorRunning
    }, {
      default: _withCtx(() => [
        _createTextVNode("Reload previews for all voices")
      ]),
      _: 1
    }, 8, ["disabled"]),
    _createVNode(_component_va_button, {
      onClick: _cache[1] || (_cache[1] = () => _ctx.reload('tts')),
      disabled: _ctx.isGeneratorRunning
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Reload previews for all TTS voices ")
      ]),
      _: 1
    }, 8, ["disabled"]),
    _createVNode(_component_va_button, {
      onClick: _cache[2] || (_cache[2] = () => _ctx.reload('vc')),
      disabled: _ctx.isGeneratorRunning
    }, {
      default: _withCtx(() => [
        _createTextVNode("Reload previews for all VC voices")
      ]),
      _: 1
    }, 8, ["disabled"]),
    _createVNode(_component_va_button, {
      onClick: _cache[3] || (_cache[3] = () => _ctx.reload('missing_preview')),
      disabled: _ctx.isGeneratorRunning
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Reload previews for all voices with missing previews ")
      ]),
      _: 1
    }, 8, ["disabled"])
  ]))
}