
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { getAllVoices, copyVoiceToRemote, deleteVoice } from '@/modules/ciso/api/voices.api'
import { Voice } from '@/modules/ciso/model/voices.model'
import { errored, isLoaded, loaded, LOADING_STATE, LoadingState, isErrored, isLoading } from '@/utils/loading-state'
import VoiceDetailsTable from '@/modules/ciso/components/voice/VoiceDetailsTable.vue'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import { useRouter } from 'vue-router'
import Panel from '@/components/UI/Panel.vue'
import { CISORoute } from '@/modules/ciso/routes'
import { useToaster } from '@/composables/useToaster'

export default defineComponent({
  name: 'Voices',
  components: { Panel, DataLoadingError, DataLoader, VoiceDetailsTable },
  setup() {
    const data = reactive({
      voices: LOADING_STATE as LoadingState<Voice[]>,
      destructiveActions: false,
      showMigrateModal: false,
      showDeleteModal: false,
    })

    const toaster = useToaster()

    const batchEditErr = ref('')

    const router = useRouter()

    const goToVoiceCreator = () => {
      router.push({ name: CISORoute.CreateVoice })
    }

    onMounted(async () => {
      reload()
    })
    const reload = async () => {
      try {
        data.voices = loaded(await getAllVoices())
      } catch (e) {
        data.voices = errored(e)
      }
    }

    const selectedVoices = reactive(new Map())
    const selectedVoicesNames = (selectedVoices: Map<any, any>) => {
      let names = ''
      selectedVoices.forEach((v, k) => {
        // next is some strange whitespace, as vuestic modal strips newlines and normal whitespace
        // eslint-disable-next-line
        names += `　　${voiceToName(v)},`
      })
      return names
    }

    const voiceToName = (v: Voice) => {
      return `${v.id}: ${v.key_tech}/${v.key_model}/${v.key_id}`
    }

    const selectVoice = async (status: boolean, v: Voice) => {
      status ? selectedVoices.set(v.id!, v) : selectedVoices.delete(v.id!)
    }

    const deleteSelectedVoices = async () => {
      let lastid = 0
      try {
        for (const [id, voice] of selectedVoices) {
          lastid = id
          await deleteVoice(voice)
          const message = `Voice "${voiceToName(voice)}" has been successfully deleted.`
          toaster && toaster.success(message)
        }
      } catch (e) {
        const message = `Voice "${lastid}" delete error: ${String(e)}`
        batchEditErr.value = message
        toaster && toaster.error(message)
      } finally {
        reload()
      }
    }

    const migrateSelectedVoices = async () => {
      let lastid = 0
      try {
        for (const [id, voice] of selectedVoices) {
          lastid = id
          await copyVoiceToRemote(voice)
          const message = `Voice "${voiceToName(voice)}" has been successfully migrated.`
          toaster && toaster.success(message)
        }
      } catch (e) {
        const message = `Voice "${lastid}" migrate error: ${String(e)}`
        batchEditErr.value = message
        toaster && toaster.error(message)
      } finally {
        reload()
      }
    }

    const remoteServerName = process.env.VUE_APP_REMOTE_COPY_ENV_NAME
    const copyToDevDisabled = process.env.NODE_ENV === 'production' && remoteServerName === 'dev' // because this works one way for devops reasons

    return {
      data,
      goToVoiceCreator,
      selectVoice,
      selectedVoices,
      selectedVoicesNames,
      isLoaded,
      isLoading,
      isErrored,
      migrateSelectedVoices,
      deleteSelectedVoices,
      batchEditErr,
      remoteServerName,
      copyToDevDisabled,
    }
  },
})
