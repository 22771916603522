export class VoicesPreviewsGenerator {
  isRunning: boolean
  keyTech: string
  processed: number
  total: number
  failed: number
  failedIds: number[]
  startedAt: Date | null
  finishedAt: Date | null

  constructor(doc: Partial<VoicesPreviewsGenerator>) {
    this.isRunning = doc.isRunning || false
    this.keyTech = doc.keyTech || 'vc & tts'
    this.processed = doc.processed || 0
    this.total = doc.total || 0
    this.failed = doc.failed || 0
    this.failedIds = doc.failedIds || []
    this.startedAt = doc.startedAt ? new Date(doc.startedAt) : null
    this.finishedAt = doc.finishedAt ? new Date(doc.finishedAt) : null

    if (!this.startedAt?.getMilliseconds()) {
      this.startedAt = null
    }

    if (!this.finishedAt?.getMilliseconds()) {
      this.finishedAt = null
    }
  }
}
