
import { computed, defineComponent, PropType, reactive, watch } from 'vue'
import { Voice, validateVoice } from '@/modules/ciso/model/voices.model'
import {
  FORM_INITIAL_STATE,
  FORM_LOADING_STATE,
  formError,
  FormState,
  formSuccess,
  isFormErrored,
  isFormLoading,
  isFormSuccess,
} from '@/utils/form-state'
import { createVoice, deleteVoice, reloadVoicePreviews, updateVoice } from '@/modules/ciso/api/voices.api'
import FormStateAlert from '@/components/UI/FormStateAlert.vue'
import { useRouter } from 'vue-router'
import { useToaster } from '@/composables/useToaster'
import PanelSubheading from '@/components/UI/PanelSubheading.vue'
import { CISORoute } from '@/modules/ciso/routes'
import { ApiError } from '@/api/_shared'
import VoiceLabelsEditor from '@/modules/ciso/components/voice/VoiceLabelsEditor.vue'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import { errored, isLoading, loaded, LOADING_STATE, LoadingState } from '@/utils/loading-state'

export default defineComponent({
  name: 'VoiceEditor',
  components: {
    DataLoadingError,
    DataLoader,
    VoiceLabelsEditor,
    PanelSubheading,
    FormStateAlert,
  },
  props: {
    voice: {
      type: Object as PropType<Voice>,
      required: true,
    },
    isNewVoice: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['reload', 'update:voice'],
  setup(props, { emit }) {
    const data = reactive({
      formState: FORM_INITIAL_STATE as FormState,
      reloadPreviews: loaded(null) as LoadingState<unknown>,
      showDeleteModal: false,
    })

    const localVoice = computed({
      get: () => props.voice,
      set: (value: Voice) => emit('update:voice', value),
    })

    const router = useRouter()
    const toaster = useToaster()

    watch(
      () => props.voice,
      () => {
        window.scrollTo({ left: 0, top: 0 })
      }
    )

    const actionHandler = async (actionFn: () => void, shouldScrollToTop = true) => {
      if (data.formState === FORM_LOADING_STATE) {
        return
      }

      if (shouldScrollToTop) {
        window.scrollTo({ left: 0, top: 0 })
      }

      data.formState = FORM_LOADING_STATE

      return actionFn()
    }

    const saveVoiceHandler = async () => {
      try {
        const voiceValidation = validateVoice(localVoice.value)

        if (voiceValidation !== true) {
          data.formState = formError('The form contains errors:', voiceValidation)
          return
        }

        localVoice.value = await updateVoice(localVoice.value)
        data.formState = formSuccess(`Voice "${localVoice.value.name}" has been successfully updated.`)
      } catch (e) {
        data.formState = formError(
          `Voice "${localVoice.value.name || localVoice.value.key_id}" could not be updated. ${e}`,
          (e as ApiError)?.errors
        )
      }
    }

    const deleteVoiceHandler = async () => {
      try {
        await deleteVoice(localVoice.value)

        const message = `Voice "${localVoice.value.key_id}" has been successfully deleted.`
        data.formState = formSuccess(message)
        toaster && toaster.success(message)

        await router.push({ name: CISORoute.Voices })
      } catch (e) {
        data.formState = formError(
          `Voice "${localVoice.value.name || localVoice.value.key_id}" could not be deleted. ${e}`,
          (e as ApiError)?.errors
        )
      }
    }

    const createVoiceHandler = async () => {
      try {
        const voiceValidation = validateVoice(localVoice.value)

        if (voiceValidation !== true) {
          data.formState = formError('The form contains errors:', voiceValidation)
          return
        }

        const voice = await createVoice(localVoice.value)

        const message = `Voice "${localVoice.value.key_tech}/${localVoice.value.key_model}/${localVoice.value.key_id}" has been successfully created.`
        data.formState = formSuccess(message)
        toaster && toaster.success(message)

        await router.push({
          name: CISORoute.EditVoice,
          params: { id: voice.id },
        })
      } catch (e) {
        data.formState = formError(
          `Voice "${localVoice.value.name || localVoice.value.key_id}" could not be created. ${e}`,
          (e as ApiError)?.errors
        )
      }
    }

    const cloneVoice = () => {
      router.push({ name: CISORoute.CreateVoice, params: { id: localVoice.value.id } })
    }

    const onEmbFile = async (file: File) => {
      if (!file) {
        return
      }

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        localVoice.value.speech_emb = reader.result?.toString().split(',')[1] // trims file mime string
      }
      reader.onerror = function (error) {
        data.formState = formError(`Could not upload emb file. Error: ${error}`)
      }
    }

    const reloadPreviews = async () => {
      try {
        if (!localVoice.value.id) {
          return
        }

        data.reloadPreviews = LOADING_STATE
        data.reloadPreviews = loaded((await reloadVoicePreviews(localVoice.value.id)) as unknown)
        emit('reload')
      } catch (e) {
        data.reloadPreviews = errored(e)
      }
    }

    const isAnyLoading = computed(() => isFormLoading(data.formState) || isLoading(data.reloadPreviews))

    return {
      data,
      localVoice,
      actionHandler,
      saveVoiceHandler,
      deleteVoiceHandler,
      createVoiceHandler,
      cloneVoice,
      isFormLoading,
      isFormErrored,
      isFormSuccess,
      toaster,
      onEmbFile,
      reloadPreviews,
      isAnyLoading,
    }
  },
})
