
import { defineComponent, onUnmounted, reactive } from 'vue'
import { getVoicePreviewsGenerator } from '@/modules/ciso/api/voices.api'
import { errored, isLoaded, loaded, LOADING_STATE, LoadingState, isErrored, isLoading } from '@/utils/loading-state'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import Panel from '@/components/UI/Panel.vue'
import { CISORoute } from '@/modules/ciso/routes'
import GoBack from '@/components/UI/GoBack.vue'
import { VoicesPreviewsGenerator } from '@/modules/ciso/model/voices-previews-generator.model'
import VoicePreviewsGeneratorStatus from '@/modules/ciso/components/voice/VoicePreviewsGeneratorStatus.vue'
import VoicePreviewsGeneratorUI from '@/modules/ciso/components/voice/VoicePreviewsGeneratorUI.vue'

export default defineComponent({
  name: 'VoicePreviews',
  components: { VoicePreviewsGeneratorUI, VoicePreviewsGeneratorStatus, GoBack, Panel, DataLoadingError, DataLoader },
  setup() {
    const data = reactive({
      isNewVoice: false,
      previewsGenerator: LOADING_STATE as LoadingState<VoicesPreviewsGenerator>,
    })

    const loadData = async () => {
      try {
        data.previewsGenerator = LOADING_STATE
        data.previewsGenerator = loaded(await getVoicePreviewsGenerator())
      } catch (e) {
        data.previewsGenerator = errored(e)
      }
    }

    const interval = setInterval(() => {
      loadData()
    }, 3000)

    onUnmounted(() => {
      clearInterval(interval)
    })

    loadData()

    return {
      data,
      isLoaded,
      isLoading,
      isErrored,
      CISORoute,
    }
  },
})
