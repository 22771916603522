import { VoiceLabels } from '@/modules/ciso/model/voice-label.model'

export class Voice {
  /* eslint-disable camelcase */
  id?: number
  name: string
  key_tech?: string
  key_model?: string
  key_id?: string
  speech_key?: string
  rating?: number
  speech_spk_emb?: number[]
  speech_emb?: string
  preview_urls: string[]
  labels: VoiceLabels
  new: boolean
  is_on_remote: boolean
  created_at?: string
  /* eslint-enable camelcase */

  constructor(doc: Partial<Voice>) {
    this.id = doc.id || undefined
    this.name = doc.name || ''
    this.key_tech = doc.key_tech || ''
    this.key_model = doc.key_model || ''
    this.key_id = doc.key_id || ''
    this.speech_key = doc.speech_key || ''
    this.rating = doc.rating || undefined
    this.speech_spk_emb = doc.speech_spk_emb || undefined
    this.speech_emb = doc.speech_emb || ''
    this.preview_urls = doc.preview_urls || []
    this.labels = doc.labels || []
    this.new = doc.new || false
    this.is_on_remote = doc.is_on_remote || false
    this.created_at = doc.created_at || undefined
  }
}

export function validateVoice(voice: Voice): true | string[] {
  const errors: string[] = []

  if (voice.rating !== undefined) {
    const rating = Number(voice.rating)
    if (rating) {
      if (rating < 0 || rating > 10) {
        errors.push('Rating must be between 0 and 10.')
      }
      const last2 = rating.toFixed(1).slice(-2)
      if (!(last2 === '.5' || last2 === '.0')) {
        errors.push('Rating must be in increments of 0.5')
      }
    }
  }

  return errors.length > 0 ? errors : true
}
