
import { computed, defineComponent, PropType, reactive, watch } from 'vue'
import { Voice } from '@/modules/ciso/model/voices.model'
import { useRouter } from 'vue-router'
import { CISORoute } from '@/modules/ciso/routes'

export default defineComponent({
  name: 'VoicesDetailsTable',
  props: {
    voices: {
      type: Array as PropType<Voice[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const router = useRouter()

    const navigateToVoice = (id: string) => {
      router.push({ name: CISORoute.EditVoice, params: { id } })
    }

    const selectedRows = reactive({} as { [key: string]: boolean })

    let lastRowIdx = -1
    const selectRow = (currentRowIdx: number, currentVoice: Voice, shift: boolean) => {
      const ok = selectedRows[currentVoice.id!]
      if (ok) {
        emit('selectedVoice', currentVoice)
      } else {
        emit('unselectedVoice', currentVoice)
      }

      if (shift && lastRowIdx !== -1) {
        const from = Math.min(currentRowIdx, lastRowIdx) + 1
        const to = Math.max(currentRowIdx, lastRowIdx) - 1

        for (let i = from; i <= to; i++) {
          const shiftVoice = props.voices[i]
          selectedRows[shiftVoice.id!] = ok
          if (ok) {
            emit('selectedVoice', shiftVoice)
          } else {
            emit('unselectedVoice', shiftVoice)
          }
        }
      }

      lastRowIdx = currentRowIdx
    }

    const deselectAll = () => {
      props.voices.forEach((v) => (selectedRows[String(v.id)] = false))
    }

    const anySelected = computed(() => Object.values(selectedRows).filter(Boolean).length >= 1)

    const remoteServerName = process.env.VUE_APP_REMOTE_COPY_ENV_NAME

    return { navigateToVoice, anySelected, deselectAll, selectedRows, selectRow, remoteServerName }
  },
})
