import { getRequestIdHeaders, simpleCharactrQuery } from '@/utils/fetch'
import { Voice } from '@/modules/ciso/model/voices.model'
import axios, { AxiosResponse } from 'axios'
import { apiErrorCatcher } from '@/api/_shared'
import { cisoApiUrl } from '@/modules/ciso/api/_shared'
import { VoiceLabelsDefinition } from '@/modules/ciso/model/voice-label.model'
import { VoicesPreviewsGenerator } from '@/modules/ciso/model/voices-previews-generator.model'

export async function getVoiceLabelsDefinition(): Promise<VoiceLabelsDefinition> {
  return simpleCharactrQuery<VoiceLabelsDefinition>(cisoApiUrl(`voice/labels`))
}

export async function getVoice(id: string): Promise<Voice> {
  const res = await simpleCharactrQuery<Voice>(cisoApiUrl(`voice/${id}`))

  return new Voice(res)
}

export async function getAllVoices(): Promise<Voice[]> {
  const res = await simpleCharactrQuery<Voice[]>(cisoApiUrl('voice'))

  return (res || []).map((v) => new Voice(v))
}

export async function reloadAllVoicePreviews(tech: string): Promise<AxiosResponse> {
  return axios
    .post(
      cisoApiUrl(`voice/reload-previews?keyTech=${tech}`),
      {},
      {
        headers: getRequestIdHeaders(),
      }
    )
    .catch(apiErrorCatcher)
}

export async function reloadVoicePreviews(id: number): Promise<AxiosResponse> {
  return axios
    .post(
      cisoApiUrl(`voice/${id}/reload-previews`),
      {},
      {
        headers: getRequestIdHeaders(),
      }
    )
    .catch(apiErrorCatcher)
}

export async function getVoicePreviewsGenerator(): Promise<VoicesPreviewsGenerator> {
  const res = await simpleCharactrQuery<VoicesPreviewsGenerator>(cisoApiUrl(`voice/reload-previews`))

  return new VoicesPreviewsGenerator(res)
}

export async function updateVoice(voice: Voice): Promise<Voice> {
  const patch: Partial<Voice> = {
    ...voice,
  }

  const spkEmb = voice.speech_spk_emb
  const shouldFix = spkEmb !== undefined && !Array.isArray(spkEmb)
  if (shouldFix) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: cast input string to proper type (because there is no ts way to achieve that in reasonable time)
    patch.speech_spk_emb = spkEmb.split(',').map(Number)
  }

  const rating = voice.rating
  if (rating !== undefined || rating !== 0) {
    patch.rating = Number(rating)
  }

  return axios
    .patch(cisoApiUrl(`voice/${voice.id}`), patch, {
      headers: getRequestIdHeaders(),
    })
    .catch(apiErrorCatcher)
    .then((r) => r.data)
    .then((data) => new Voice(data))
}

export async function deleteVoice(voice: Voice): Promise<unknown> {
  return axios
    .delete(cisoApiUrl(`voice/${voice.id}`), {
      headers: getRequestIdHeaders(),
    })
    .catch(apiErrorCatcher)
}

export async function createVoice(voice: Voice): Promise<Voice> {
  const payload: Partial<Voice> = {
    ...voice,
  }

  const spkEmb = voice.speech_spk_emb
  const shouldFix = spkEmb !== undefined && !Array.isArray(spkEmb)
  if (shouldFix) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: cast input string to proper type (because there is no ts way to achieve that in reasonable time)
    payload.speech_spk_emb = spkEmb.split(',').map(Number)
  }

  const rating = voice.rating
  if (rating !== undefined || rating !== 0) {
    payload.rating = Number(rating)
  }

  return axios
    .post(cisoApiUrl('voice'), payload, {
      headers: getRequestIdHeaders(),
    })
    .then((r) => r.data)
    .then((data) => new Voice(data))
    .catch(apiErrorCatcher)
}

export async function copyVoiceToRemote(voice: Voice): Promise<Voice> {
  const payload: Partial<Voice> = {
    ...voice,
  }
  payload.id = 0

  const spkEmb = voice.speech_spk_emb
  const shouldFix = spkEmb !== undefined && !Array.isArray(spkEmb)
  if (shouldFix) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: cast input string to proper type (because there is no ts way to achieve that in reasonable time)
    payload.speech_spk_emb = spkEmb.split(',').map(Number)
  }

  const rating = voice.rating
  if (rating !== undefined || rating !== 0) {
    payload.rating = Number(rating)
  }

  return axios
    .post(cisoApiUrl('remote/voice'), payload, {
      headers: getRequestIdHeaders(),
    })
    .then((r) => r.data)
    .then((data) => new Voice(data))
    .catch(apiErrorCatcher)
}
