
import { computed, defineComponent, PropType } from 'vue'
import { VoicesPreviewsGenerator } from '@/modules/ciso/model/voices-previews-generator.model'
import { formatSecondsDuration } from '@/utils/time'

export default defineComponent({
  name: 'VoicePreviewsGeneratorStatus',
  props: {
    status: {
      type: Object as PropType<VoicesPreviewsGenerator>,
      required: true,
    },
  },
  setup(props) {
    const progress = computed(() => Number(((props.status.processed / props.status.total) * 100).toFixed(2)))

    const runningTime = computed(() => {
      if (props.status.startedAt && props.status.finishedAt) {
        return formatSecondsDuration((props.status.finishedAt.getTime() - props.status.startedAt.getTime()) / 1000)
      } else if (props.status.startedAt) {
        return formatSecondsDuration((new Date().getTime() - props.status.startedAt.getTime()) / 1000)
      } else {
        return '-'
      }
    })

    return { progress, runningTime }
  },
})
