
import { computed, defineComponent, reactive, watch } from 'vue'
import { getVoice } from '@/modules/ciso/api/voices.api'
import { Voice } from '@/modules/ciso/model/voices.model'
import { errored, isLoaded, loaded, LOADING_STATE, LoadingState, isErrored, isLoading } from '@/utils/loading-state'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import { RouteRecordName, useRoute } from 'vue-router'
import VoiceEditor from '@/modules/ciso/components/voice/VoiceEditor.vue'
import Panel from '@/components/UI/Panel.vue'
import { CISORoute } from '@/modules/ciso/routes'
import GoBack from '@/components/UI/GoBack.vue'

export default defineComponent({
  name: 'Voice',
  components: { GoBack, Panel, VoiceEditor, DataLoadingError, DataLoader },
  setup() {
    const data = reactive({
      isNewVoice: false,
      voice: LOADING_STATE as LoadingState<Voice>,
    })

    const route = useRoute()

    const title = computed(() => {
      if (route.name === CISORoute.CreateVoice) {
        return 'Create new voice'
      } else if (isLoaded(data.voice)) {
        return 'Voice: ' + `${data.voice.data.key_tech}/${data.voice.data.key_model}/${data.voice.data.key_id}`
      } else {
        return 'Voice details'
      }
    })

    const getClonedVoice = async (id: string): Promise<Voice> => {
      const res = await getVoice(id)

      return {
        ...res,
        id: undefined,
      }
    }

    const loadVoice = async (id: string | string[]) => {
      try {
        data.voice = LOADING_STATE

        if (route.name === CISORoute.CreateVoice) {
          data.voice = loaded(id ? await getClonedVoice(String(id)) : new Voice({ id: undefined }))
          data.isNewVoice = true
        } else {
          data.voice = loaded(await getVoice(String(id)))
          data.isNewVoice = false
        }
      } catch (e) {
        data.voice = errored(e)
      }
    }

    const updateVoice = (voice: Voice) => {
      data.voice = loaded(voice)
    }

    type WatchT = [string | string[], RouteRecordName | null | undefined]
    watch(
      (): WatchT => [route.params.id, route.name],
      ([id, name]: WatchT) => {
        if (name === CISORoute.CreateVoice || name === CISORoute.EditVoice) {
          loadVoice(id)
        }
      }
    )

    loadVoice(route.params.id)

    return {
      data,
      title,
      isLoaded,
      isLoading,
      isErrored,
      CISORoute,
      loadVoice,
      updateVoice,
    }
  },
})
