
import { defineComponent } from 'vue'
import { RouteLocationRaw, useRouter } from 'vue-router'

export default defineComponent({
  name: 'GoBack',
  props: {
    to: {
      required: false,
    },
  },
  setup(props) {
    const router = useRouter()

    const navigate = () => {
      if (props.to) {
        router.push(props.to as RouteLocationRaw)
      } else {
        router.back()
      }
    }

    return { navigate }
  },
})
