
import { defineComponent } from 'vue'
import { reloadAllVoicePreviews } from '@/modules/ciso/api/voices.api'
import { useToaster } from '@/composables/useToaster'

export default defineComponent({
  name: 'VoicePreviewsGeneratorUI',
  props: {
    isGeneratorRunning: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const toaster = useToaster()

    const reload = async (tech: string) => {
      try {
        await reloadAllVoicePreviews(tech)
      } catch (e) {
        toaster && toaster.error(String(e))
      }
    }

    return { reload }
  },
})
